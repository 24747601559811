// @flow
import React, { useState } from 'react';
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import swal from 'sweetalert';
import type { Node } from '../../types';
import LeadMagnet from '../LeadMagnet/LeaadMagnet';

type Props = {
  post: Node
};

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date } = post.frontmatter;
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        swal("Merci !", "Vous allez recevoir un email de bienvenue", "success");
      })
      .catch((error: Error) => {
        swal("Erreur...", "Il y a eu un problème... Veuillez réessayer plus tard", "error");
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">Revenir</Link>
      <LeadMagnet/>

      <div className={styles['post__content']}>
        <Content body={html} title={title} />
      </div>

      {/* <form onSubmit={handleSubmit} className={styles.EmailListForm}>
        <h2>Tu rates quelque chose !</h2>
        <p>
          Dans mon blog, tu ne vois que la face <strong>émmergée</strong> de l'iceberg.<br/><br/>
          J'envoie des emails réguliers à mes abonnés dans lesquels tu as <strong>énormement</strong> de valeur.<br/><br/>
          Tu recevras des conseils pratiques qui n'apparaitrons <strong>jamais</strong> dans le blog.<br/><br/>
          Tu aura un <strong>accès exclusif</strong> à des promotions sur mes nouvelles formations.<br/><br/>
          <strong>Ça ne te coûte rien !</strong> Viens voir par toi-même le prochain email et, s'il ne te plaît pas, tu es libre de te désabonner à tout moment.<br/>
        </p>
        <div className={styles.Wrapper}>
          <input
            placeholder="Email"
            name="email"
            type="text"
            onChange={handleEmailChange}
          />
          <button type="submit">Envoyer</button>
        </div>
      </form> */}

      <div className={styles['post__footer']}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        {/* <Author /> */}
      </div>

      {/* <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div> */}
    </div>
  );
};

export default Post;
